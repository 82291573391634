export const PARAM = {
  engineId: 2,
  accountPage: 2,
  entityType: 3,
  viewId: 4,
  collectionId: 4,
  collectionPage: 5,
  viewPage: 5,
  configurePage: 6,
};

export function getParamFromURL(param) {
  let pathname = window.location.pathname;
  let data = pathname.split("/");
  return data[param] || "";
}

export function getChargebeePlan() {
  if (window.location.hostname === "app.keyspider.io") {
    return {
      site: "keyspider",
    };
  } else {
    return {
      site: "keyspider-test",
    };
  }
}
