import React, { useEffect } from "react";
import { PageContainer } from "../container";
import { useHistory, useParams } from "react-router-dom";
import { showError } from "../../shared/widgets";
import { UserService } from "../../shared/service";
import styles from "./activateaccount.module.css";

export function ActivateAccountPage() {
  let history = useHistory();
  let params = useParams();

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = (values) => {
    console.log("Received values of form: ", values);
    let data = {
      key: params.key,
      user_id: params.userId
    };
    UserService.activate(data)
      .then((response) => {
        console.log(response, params);
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        // passwordForm.resetFields();
        // setRestorePassword(false);
        // getEngines(record === currentEngineId);
        history.push("/");
      });
  };

  return (
    <PageContainer>
      <div>Loading...</div>
    </PageContainer>
  );
}
