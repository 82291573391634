import dayjs from "dayjs";

export function getDateFromEpoch(time){
    if(time?.toString().length === 10) {
        return new Date(time * 1000); 
    } else {
        return new Date(time); 
    }
}

export function getEpochFromDate(){

}

export function isExpired(date) {
    return dayjs().isAfter(date)
}

export function daysRemaining(date){
    let date1 = dayjs(getDateFromEpoch(date));
    return dayjs().diff(date1, 'days');
}