import { storeCreator } from "./util";

const store = {
  views: [],
  currentView: null,
  currentViewId: null
};

const [useViewStore, viewStoreApi] = storeCreator((setState, getState) => ({
  ...store,
  setViews: (views) => {
    setState(() => {
      return { views: views };
    });
  },
  getViews: () => {
    return { views: getState().views };
  },
  getCurrentView: () => {
    return { currentView: getState().currentView };
  },
  setCurrentView: (view, id) => {
    setState(() => {
        return { currentView: view, currentViewId: id };
      });
  },
  reset: () => {
    setState(() => ({
      ...store,
    }));
  },
}));

export { useViewStore, viewStoreApi };
