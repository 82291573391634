import { get } from "./service";

export const AnalyticService = {
  getUsage(startDate, EndDate) {
    return get(`/analytics/resource_usage?start_date=${startDate}&end_date=${EndDate}&doc_count=yes&query_count=yes`);
  },
  getUsageForMonths(EndDate, months) {
    return get(`/analytics/query_usage?end_date=${EndDate}&month_count=${months}`);
  },
};
