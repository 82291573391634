import { get, post, put } from "./service";

export const SynonymsService = {
  list(engineName, collectionName, page, size) {
    return get(
      `/synonyms/List?engine=${engineName}&collection=${collectionName}&start=${page}&rows=${size}`
    );
  },
  create(data) {
    return post("/synonyms/Create", { body: data }, true);
  },
  update(data) {
    return put("/synonyms/Update", { body: data }, true);
  },
  remove(data) {
    return put(`/synonyms/Remove`, { body: data }, true);
  },
};
