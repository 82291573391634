import { storeCreator } from "./util";

const store = {
  list: [],
  total: 0,
  editSynonym: null,
};

const [useSynonymStore, synonymStoreApi] = storeCreator(
  (setState, getState) => ({
    ...store,
    setList: (data, total) => {
      setState(() => {
        return { list: data, total: total };
      });
    },
    getList: () => {
      return { list: getState().list };
    },
    setEdit: (data) => {
      setState(() => {
        return { editSynonym: data };
      });
    },
    getEdit: () => {
      return { data: getState().editSynonym };
    },
    reset: () => {
      setState(() => ({
        ...store,
      }));
    },
  })
);

export { useSynonymStore, synonymStoreApi };
