import { post, get, deleteReq, put } from "./service";

export const ViewService = {
  getViews(engineName) {
    return get(`/view/GetViews?engine=${engineName}`);
  },
  getView(engineName, viewName) {
    return get(`/view/GetView?engine=${engineName}&view=${viewName}`);
  },
  create(data) {
    return post("/view/Create", { body: data }, true);
  },
  update(data) {
    return put("/view/Update", { body: data }, true);
  },
  delete(engineName, viewName) {
    return deleteReq(`/view/Delete?engine=${engineName}&view=${viewName}`, {}, true);
  },
};
