import { EngineService } from "../service";
import { engineStoreApi } from "../store";

export function getEngines(shouldUpdateEngine = false) {
  EngineService.getEngines()
    .then((response) => {
      engineStoreApi.getState().setEngines(response.engines);
      if (
        engineStoreApi.getState().getCurrentEngine().currentEngine === null ||
        shouldUpdateEngine
      ) {
        if (response.engines.length > 0) {
          getEngine(response.engines[0]);
        }
      }
    })
    .catch((error) => {
      if (error.code === 404) {
        engineStoreApi.getState().setEngines([]);
        window.location.replace("/setup");
      } else if (error.code === 498) {
        localStorage.removeItem("token");
        localStorage.removeItem("mini_key");
      }
    });
}

export function getEngine(currentEngineId) {
  EngineService.getEngine(currentEngineId).then((response) => {
    if (response.engine) {
      engineStoreApi
        .getState()
        .setCurrentEngine(response.engine, currentEngineId);
    }
  });
}

export function getEngineChild() {
  EngineService.getChild().then((response) => {
    if (response.engines) {
      engineStoreApi.getState().setDetails(response.engines);
    }
  });
}
