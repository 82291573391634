import React, { useEffect, useState } from "react";
import {
  Button,
  BUTTON_TYPE,
  Input,
  Form,
  useForm,
  Field,
  TextArea,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
} from "../widgets";
import styles from "./styles.module.css";

export function MappingForm({
  onFinish,
  data = {},
  form,
  reqFields = [],
  editMode = false,
  onRefreshKey = () => {},
}) {
  // const [form] = useForm();
  // const [requiredFields, setRequiredFields] = useState("");

  // useEffect(() => {
  //   onFormLoad(form);
  //   setRequiredFields(data.required_fields || "default");
  // }, []);

  // useEffect(() => {
  //   form.validateFields(["required_fields"]);
  // }, [form, requiredFields]);

  // const onRadioChange = (e) => {
  //   setRequiredFields(e.target.value);
  // };

  return (
    <Form
      form={form}
      name="create_mapping_form"
      onFinish={onFinish}
      initialValues={{
        mapping: data.mapping,
        description: data.description,
        required_fields: data.required_fields,
        key: data.key,
        root_id: data.root_id
      }}
    >
      <div className={`paragraph300 ${styles.fieldHeader}`}>Mapping name</div>
      <Field
        name="mapping"
        rules={[
          {
            required: true,
            message: "Please input your mapping name!",
          },
        ]}
      >
        <Input type="text" readOnly={editMode} />
      </Field>
      <div className={`paragraph300 ${styles.fieldHeader}`}>Description</div>
      <Field name="description" rules={[]}>
        <Input type="text" />
      </Field>
      <div className={`paragraph300 ${styles.fieldHeader}`}>API map field customization</div>
      <Field
        name="required_fields"
        rules={[
          {
            required: true,
            type: "array",
            message: "Please select atleast one field",
          },
        ]}
      >
        {/* <RadioGroup value={requiredFields} onChange={onRadioChange}>
          <Radio value="default">Default</Radio>
          <Radio value="all">All</Radio>
          <Radio value="search_fields">Search Fields</Radio>
        </RadioGroup> */}
        <CheckboxGroup className={styles.searchFields}>
          {reqFields.map((item) => {
            return (
              <Checkbox value={item.search_field}>{item.search_field}</Checkbox>
            );
          })}
        </CheckboxGroup>
      </Field>
      <div className={`paragraph300 ${styles.fieldHeader}`}>Organization Id</div>
      <Field name="root_id">
        <Input readOnly={true} />
      </Field>
      <div className={`paragraph300 ${styles.fieldHeader}`}>API key</div>
      <Field name="key">
        <TextArea readOnly={true} />
      </Field>
      <Button type={BUTTON_TYPE.LINK} onClick={onRefreshKey} style={{
            paddingLeft: "0",
            top: "-2rem"
      }}>
        Refresh key
      </Button>
      {/* <Field
        name="search_fields"
        rules={[
          {
            required: true,
            message: "Please select your favourite colors!",
            type: "array",
          },
        ]}
      >
        <CheckboxGroup className={styles.searchFields}>
          {reqFields.map((item) => {
            return <Checkbox value={item}>{item}</Checkbox>;
          })}
        </CheckboxGroup>
      </Field> */}
      {/* <Field>
        <Button type={BUTTON_TYPE.PRIMARY} htmlType="submit" block={true}>
          Continue
        </Button>
      </Field> */}
    </Form>
  );
}
