import { showSuccess, showError } from "../widgets";
import { clarity } from 'react-microsoft-clarity';

// const baseUrl = "https://server1.webr-env01.xyz:8181/portal/api/v2";
// const searchUrl = "https://server1.webr-env01.xyz:8282/api/v1";
// const baseUrl = "https://app.keyspider.io:8443/portal/api/v2";
// const searchUrl = "https://app.keyspider.io:8443/api/v1";
let baseUrl = "https://app.keyspider.io/portal/api/v2";
let searchUrl = "https://app.keyspider.io/api/v1";

function init() {
  if (window.location !== "app.keyspider.io") {
    baseUrl = "https://app.keyspider.io:8443/portal/api/v2";
    searchUrl = "https://app.keyspider.io:8443/api/v1";
  }
}
//init();

export function get(url, options = {}, showToast, isSearch) {
  options.method = "GET";
  return createReq(url, options, showToast, isSearch);
}

export function post(url, options = {}, showToast) {
  options.method = "POST";
  options.body = JSON.stringify(options.body);
  return createReq(url, options, showToast);
}

export function put(url, options = {}, showToast) {
  options.method = "PUT";
  options.body = JSON.stringify(options.body);
  return createReq(url, options, showToast);
}

export function deleteReq(url, options = {}, showToast) {
  options.method = "DELETE";
  return createReq(url, options, showToast);
}

function createReq(url, options, showToast, isSearch = false) {
  // append other header , tokens here
  const headers = Object.assign(
    {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    options.headers || {}
  );
  options.headers = headers;
  let reqUrl;
  if (isSearch) {
    reqUrl = searchUrl + url;
  } else {
    reqUrl = baseUrl + url;
  }
  return fetch(reqUrl, options)
    .then((response) => CheckError(response))
    .then((response) => {
      if (response.code === 200 && showToast) {
        showSuccess(response.message);
      } else if (showToast) {
        showError(response.message);
      }
      return response;
    })
    .catch((error) => {
      if (error.code === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("mini_key");
        // window.location.assign(
        //   window.location.protocol + "//" + window.location.hostname + "/login"
        // );
      }
      return Promise.reject(error);
    });
}

function CheckError(response) {
  if (response.status >= 200 && response.status <= 299) {
    return response.json();
  } else {
    return response.json().then((data) => {
      return Promise.reject(data);
    });
  }
}
