import React, { useEffect, useState } from "react";
import {
  Button,
  BUTTON_TYPE,
  Input,
  Form,
  Field,
  FieldList,
  RadioGroup,
  RadioButton,
  Slider,
  Select,
  SelectItem,
} from "../widgets";
import { Icon, Icon_Size, DropdownIcon } from "../widgets";
import styles from "./styles.module.css";

export function AddUsersForm({
  onFinish,
  data = {},
  form,
  allroles = [],
  editMode = false,
}) {
  return (
    <Form
      form={form}
      name="add_users_form"
      onFinish={onFinish}
      initialValues={{
        first_last_name: data.first_last_name || data.account_first_last_name,
        user_email: data.user_email,
        password: data.password,
        roles: data.roles && data.roles[0],
      }}
      className="addUsersForm"
    >
      <div className={`${styles.fieldHeader} paragraph300`}>Email address</div>
      <Field
        name="user_email"
        rules={[
          {
            required: true,
            message: "Please input your email here",
          },
        ]}
        className={"flexField"}
      >
        <Input
          // prefix={<UserOutlined className="site-form-item-icon" />}
          type={"email"}
          placeholder="Email"
          autocomplete="off"
          readOnly={editMode}
        />
      </Field>
      <div className={`${styles.fieldHeader} paragraph300`}>Name</div>
      <Field
        name="first_last_name"
        rules={[
          {
            required: true,
            message: "Please input your name here",
          },
        ]}
        className={"flexField"}
      >
        <Input
          // prefix={<UserOutlined className="site-form-item-icon" />}
          type={"text"}
          placeholder="Name"
          readOnly={editMode}
        />
      </Field>
      {/* {!editMode && (
        <>
          <div className={`${styles.fieldHeader} paragraph300`}>Password</div>
          <Field
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
            className={"flexField"}
          >
            <Input
              // prefix={<LockOutlined className="site-form-item-icon" />}
              type={"password"}
              placeholder="Password"
              autocomplete="off"
            />
          </Field>
        </>
      )} */}
      <div className={`${styles.fieldHeader} paragraph300`}>Select role</div>
      <Field
        name="roles"
        rules={[
          {
            required: true,
            message: "Please select your favourite colors!",
          },
        ]}
      >
        <Select
          placeholder="Please select roles"
          suffixIcon={<Icon src={DropdownIcon} size={Icon_Size.Icon12} />}
        >
          {allroles.map((item) => {
            return <SelectItem value={item.role}>{item.role}</SelectItem>;
          })}
        </Select>
      </Field>
    </Form>
  );
}
