import React, {useEffect} from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  LoginPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  SignupPage,
  ActivateAccountPage,
  ActivateUserAccountPage,
} from "./unauth";
import { Spin } from "./shared/widgets";
import { useEngineStore } from "./shared/store";
import { PrivateRoute } from "./auth";
import { NotActivePage } from "./shared/components/account_status";
import { clarity } from 'react-microsoft-clarity';

import "./app.css";

function App() {
  let { isLoading } = useEngineStore((state) => ({
    isLoading: state.isLoading,
  }));
  useEffect(()=>{clarity.init('l4x9noma5e');}, []);
  return (
    <div className="App">
      <Spin spinning={isLoading} wrapperClassName="mainLoader">
        <Router>
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/resetpassword/:userId/:key">
              <ResetPasswordPage />
            </Route>
            <Route path="/activate/:userId/:key">
              <ActivateAccountPage />
            </Route>
            <Route path="/user/activate/:userId/:key">
              <ActivateUserAccountPage />
            </Route>
            <Route path="/forgotpassword">
              <ForgotPasswordPage />
            </Route>
            <Route path="/signup">
              <SignupPage />
            </Route>
            <Route path="/activate">
              <NotActivePage status={"notactive"}/>
            </Route>
            <Route path="/">
              <PrivateRoute />
            </Route>
          </Switch>
        </Router>
      </Spin>
    </div>
  );
}

export default App;
