import React from 'react';
import { Slider as AntSlider} from 'antd';
import "antd/es/slider/style/css";
// import styles from './button.module.css';

export function Slider({
    ...props
}) {
  return (
    <AntSlider {...props} />
  );
}
