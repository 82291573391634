import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import {
  Button,
  BUTTON_TYPE,
  Input,
  Form,
  Field,
  SelectItem,
  Select,
} from "../widgets";
import styles from "./styles.module.css";

export function CreateFieldSettingForm({
  onFinish,
  data = {},
  form,
  isEdit = false,
  fieldTypes = [],
  fieldsList,
}) {
  const onDebounceFieldChange = debounce(() => onFieldsChange(), 300);

  const [isReadOnly, setIsReadOnly] = useState(true);

  useEffect(() => {
    let fieldsValue = form.getFieldsValue();
    if (!fieldsValue.selector_type) {
      form.setFieldsValue({
        selector_type: "css",
      });
    }
  }, []);

  const onFieldsChange = (allFields) => {
    let fieldsValue = form.getFieldsValue();
    if (fieldsValue.field_name) {
      if (fieldsValue.field_name in fieldsList) {
        form.setFieldsValue({
          field_type: fieldsList[fieldsValue.field_name].type,
        });
        setIsReadOnly(true);
      } else {
        setIsReadOnly(false);
      }
    }
  };

  return (
    <Form
      name="create_field_setting_form"
      form={form}
      initialValues={{
        field_name: data.field_name,
        field_type: data.field_type,
        selector_string: data.selector_string,
        selector_type: data.selector_type,
      }}
      onFinish={onFinish}
      onFieldsChange={(_, allFields) => {
        onDebounceFieldChange();
      }}
    >
      <div className={`paragraph300 ${styles.fieldHeader}`}>Field name</div>
      <Field
        name="field_name"
        rules={[
          {
            required: true,
            message: "Please input your name here",
          },
        ]}
        className={"flexField"}
      >
        <Input
          // prefix={<UserOutlined className="site-form-item-icon" />}
          readOnly={isEdit}
          type={"text"}
          placeholder="Field Name"
        />
      </Field>
      <div className={`paragraph300 ${styles.fieldHeader}`}>Field type</div>
      <Field
        name="field_type"
        rules={[
          {
            required: true,
            message: "Please input your name here",
          },
        ]}
        className={"flexField"}
      >
        <Select
          placeholder="Field type"
          defaultValue="text_general"
          disabled={isReadOnly}
        >
          {fieldTypes.map((item) => {
            return <SelectItem value={item}>{item}</SelectItem>;
          })}
        </Select>
      </Field>
      <div className={`paragraph300 ${styles.fieldHeader}`}>
        Selector string
      </div>
      <Field
        name="selector_string"
        rules={[
          {
            required: true,
            message: "Please input your email here",
          },
        ]}
        className={"flexField"}
      >
        <Input type={"text"} placeholder="Selector String" />
      </Field>
      <div className={`paragraph300 ${styles.fieldHeader}`}>Selector type</div>
      <Field
        name="selector_type"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
        className={"flexField"}
      >
        <Select placeholder="Selector type" defaultValue="css">
          {selectortype.map((item) => {
            return <SelectItem value={item}>{item}</SelectItem>;
          })}
        </Select>
      </Field>
    </Form>
  );
}

const selectortype = ["css", "xpath"];
