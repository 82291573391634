// export function Icon({ src, className }) {
//   return <img src={src} alt="" className={className} />;
// }

import React from "react";
// import { ReactSVG } from "react-svg";

import SVG from "react-inlinesvg";

import styles from "./icon.module.css";

// export const Icon_Size = {
//   Icon8: "icon8",
//   Icon12: "icon12",
//   Icon16: "icon16",
//   Icon18: "icon18",
//   Icon20: "icon20",
//   Icon24: "icon24",
//   Icon32: "icon32",
//   Icon36: "icon36",
//   Icon40: "icon40",
// };

export const Icon_Size = {
  Icon8: ".8rem",
  Icon12: "1.2rem",
  Icon16: "1.6rem",
  Icon18: "1.8rem",
  Icon20: "2rem",
  Icon24: "2.4rem",
  Icon32: "3.2rem",
  Icon36: "3.6rem",
  Icon40: "4rem",
};

export const Icon_Color = {
  Dark: styles.iconDark,
  Medium: styles.iconMedium,
  Light: styles.iconLight,
  Inverse: styles.iconInverse,
  Blue500: styles.iconBlue500,
  Other: styles.iconOther,
};

// export function Icon({ src, className, size = Icon_Size.Icon12, disabled  }) {
//   const getSize = () => {
//     let cssClass =  ``;
//     cssClass= `${styles[size]} `
//     if(disabled) {
//       cssClass += `${styles.disabled} `
//     }
//     return cssClass;
//   };

//   return (
//     <ReactSVG
//       src={src}
//       alt=""
//       className={`${className} ${getSize()} ${styles.icon}`}
//       wrapper="span"
//       useRequestCache={true}
//     />
//   );
// }

export function Icon({
  src,
  className,
  size = Icon_Size.Icon12,
  width,
  color = {
    default: Icon_Color.Other,
    selected: Icon_Color.Blue500,
    disabled: Icon_Color.Other,
  },
  disabled,
  selected,
}) {
  const getClass = () => {
    let cssClass = ``;
    if (disabled) {
      cssClass += `${styles.disabled} ${color.disabled}`;
    } else if (selected) {
      cssClass += `${color.selected} `;
    } else if (color.default) {
      cssClass += `${color.default} `;
    }
    // if (hover) {
    //   cssClass += `${color.hover} `;
    // }
    return cssClass;
  };

  return (
    <span className={`${className} ${styles.icon} ${getClass()}`}>
      <SVG
        src={src}
        useRequestCache={true}
        width={width || size}
        height={size}
      />
    </span>
  );
}
