import React from 'react';
import { PageHeader as AntPageHeader } from 'antd';
import 'antd/es/page-header/style/css'; 
// import styles from './button.module.css';

export function PageHeader({
    children,
    ...props
}) {
  return (
    <AntPageHeader {...props}>
        {children}
    </AntPageHeader>
  );
}
