import { get, post, deleteReq, put } from "./service";

export const CollectionService = {
  createCollection(data) {
    return post("/collection/CreateCollection", { body: data }, true);
  },
  deleteCollection(engineName, collectionName) {
    return deleteReq(
      `/collection/DeleteCollection?engine=${engineName}&collection=${collectionName}`, {}, true
    );
  },
  getCollections(engineName) {
    return get(`/collection/GetCollections?engine=${engineName}`);
  },
  getCollection(engineName, collectionName) {
    return get(
      `/collection/GetCollection?engine=${engineName}&collection=${collectionName}`
    );
  },
  defaultConfigurations(dataSourceType) {
    return get(
      `/collection/DefaultConfigurations?data_source_type=${dataSourceType}`
    );
  },
  updateConfig(data) {
    return put(`/collection/UpdateConfig`, { body: data }, true);
  },
  updateSchedule(data) {
    return put(`/collection/UpdateSchedule`, { body: data }, true);
  },
  addWebDataSource(data) {
    return put(`/collection/AddWebDataSource`, { body: data }, true);
  },
  updateDataSource(data) {
    return put(`/collection/UpdateDataSource`, { body: data }, true);
  },
  deleteDataSource(data) {
    return put(`/collection/DeleteDataSource`, { body: data }, true);
  },
  configDefault(data) {
    return put(`/collection/ConfigDefault`, { body: data }, true);
  }
};
