import React from "react";
import { Form, Field, Password } from "../widgets";
import { debounce } from "lodash";
import styles from "./styles.module.css";

export function UpdatePasswordForm({
  form,
  onFinish,
  data = {},
  onFieldsChange,
}) {
  const onDebounceFieldChange = debounce(() => onFieldsChange(), 300);

  return (
    <Form
      name="update_password_form"
      form={form}
      onFinish={onFinish}
      initialValues={{
        password: data.password,
        confirm: data.confirm,
      }}
      onFieldsChange={(_, allFields) => {
        onDebounceFieldChange();
      }}
      className={styles.updatePasswordForm}
    >
      <div className={`paragraph300 ${styles.fieldHeader}`}>Password </div>
      <Field
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
          {
            min: 8,
            message: "Should be minimum of 8 characters",
          },
          {
            max: 100,
            message: "Should not be more than 100 characters",
          },
        ]}
        hasFeedback
      >
        <Password />
      </Field>
      <div className={`paragraph300 ${styles.fieldHeader}`}>
        Confirm Password{" "}
      </div>
      <Field
        name="confirm"
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The two passwords that you entered do not match!")
              );
            },
          }),
        ]}
      >
        <Password />
      </Field>
    </Form>
  );
}
