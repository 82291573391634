import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Field,
  FieldList,
  Select,
  SelectItem,
  Slider,
  Input,
} from "../widgets";
import { debounce } from "lodash";
import { MinusCircleOutlined } from "@ant-design/icons";
import {
  Icon,
  Icon_Size,
  DropdownIcon,
  BluePlusIcon,
  RightArrowIcon,
  Icon_Color,
} from "../widgets";
import styles from "./styles.module.css";

export function FieldWeightForm({
  onFinish,
  data = {},
  form,
  searchFields = [],
  onFormChange,
  defaultFields = [],
  isReadonly = false,
}) {
  const [fieldsData, setFieldsData] = useState([]);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFieldsData(searchFields);
  }, [searchFields]);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const onDebounceFieldChange = debounce(() => onFieldsChange(), 300);

  const onFieldsChange = () => {
    let fields = form.getFieldValue("fields");
    if (fields) {
      updatedQueries(fields);
    }
    onFormChange();
  };

  const updatedQueries = (fields = []) => {
    let fieldNames = [];
    fields.forEach((item) => {
      if (item && item.fieldname) {
        fieldNames.push(item.fieldname);
      }
    });
    let filteredFieldNames = searchFields.filter(
      (item) => fieldNames.indexOf(item.search_field) === -1
    );
    setFieldsData(filteredFieldNames);
  };

  return (
    <Form
      form={form}
      name="field_Weight_form"
      id="fieldWeightForm"
      onFinish={onFinish}
      initialValues={{
        fields: data.fields,
      }}
      className="addFieldsForm"
      onFieldsChange={(_, allFields) => {
        onDebounceFieldChange();
      }}
    >
      <FieldList name="fields">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <div key={field.key} className={styles.fieldWeightForm}>
                <div className={styles.field}>
                  <span className="paragraph300">Field</span>
                  <Field
                    {...field}
                    name={[field.name, "fieldname"]}
                    fieldKey={[field.fieldKey, "fieldname"]}
                    rules={[{ required: true, message: "Missing field name" }]}
                  >
                    {!isRemovable(field, defaultFields, form) ? (
                      <Input readOnly={true} />
                    ) : (
                      <Select
                        suffixIcon={
                          <Icon src={DropdownIcon} size={Icon_Size.Icon12} />
                        }
                        placeholder="Please select search field"
                        disabled={isReadonly}
                      >
                        {fieldsData &&
                          fieldsData.map((item) => {
                            return defaultFields.indexOf(item) === -1 && (
                              <SelectItem value={item}>
                                {item?.split ? item.split("_ksp")[0] : item}
                              </SelectItem>
                            );
                          })}
                      </Select>
                    )}
                  </Field>
                </div>
                <div className={styles.arrow}>
                  <Icon
                    src={RightArrowIcon}
                    size={Icon_Size.Icon16}
                    color={{
                      default: Icon_Color.Dark,
                    }}
                  />
                </div>
                <div className={styles.field}>
                  <span className="paragraph300">Weight</span>
                  <Field
                    {...field}
                    name={[field.name, "fieldweight"]}
                    fieldKey={[field.fieldKey, "fieldweight"]}
                    rules={[{ required: true, message: "Missing fieldweight" }]}
                  >
                    <Slider
                      min={1}
                      max={10}
                      disabled={isReadonly}
                      // trigger={["hover"]}
                      // tooltipVisible={true}
                      // getTooltipPopupContainer={() => document.getElementById("fieldWeightForm")}
                    />
                  </Field>
                </div>
                {!isReadonly && isRemovable(field, defaultFields, form) ? (
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                ) : (
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                )}
              </div>
            ))}
            <Field>
              <Button
                type="link"
                onClick={() => add()}
                className={styles.addFieldButton}
                icon={<Icon src={BluePlusIcon} size={Icon_Size.Icon16} />}
                disabled={isReadonly}
              >
                Add field
              </Button>
            </Field>
          </>
        )}
      </FieldList>
      {/* <Field>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Field> */}
    </Form>
  );
}

function isRemovable(data, defaultFields, form) {
  let fields = form.getFieldsValue().fields || [];
  if (fields) {
    return fields[data.name]
      ? defaultFields.indexOf(fields[data.name]["fieldname"]) === -1
      : true;
  }
}
