export const Roles = {
  Create_Engine: "Create Engine",
  Delete_Engine: "Delete Engine",
  Create_Collection: "Create Collection",
  Delete_Collection: "Delete Collection",
  Get_Engine_details: "Get Engine details",
  Get_Engines_name: "Get Engines name",
  Get_collection_config: "Get collection config",
  Create_View: "Create View",
  Delete_View: "Delete View",
  Update_View: "Update View",
  Get_Collections: "Get Collections",
  Get_views: "Get views",
  Get_view_configuration: "Get view configuration",
  Create_new_user: "Create new user",
  Get_user_details: "Get user details",
  Get_all_user_details: "Get all user details",
  Get_user_roles: "Get user roles",
  DeleteUser: "DeleteUser",
  Update_user_roles: "Update user roles",
  UpdateStatus: "UpdateStatus",
  Update_collection_configuration: "Update collection configuration",
  Set_collection_configuration_default: "Set collection configuration default",
  Get_default_configurations: "Get default configurations",
  Update_data_source: "Update data source",
  Delete_data_sources: "Delete data sources",
  Create_mapping: "Create mapping",
  Delete_mapping: "Delete mapping",
  Get_mappings: "Get mappings",
  Get_mapping: "Get mapping",
  Update_mapping_configuration: "Update mapping configuration",
  Add_Indexing_datasource: "Add Indexing datasource",
  Delete_Indexing_datasource: "Delete Indexing datasource",
  Recrawl_Indexing_datasource: "Recrawl Indexing datasource",
  Logout: "Logout",
  List_Fields: "List Fields",
  List_weight: "List weight",
  Update_weight: "Update weight",
  Update_synonyms: "Update synonyms",
  Remove_synonyms: "Remove synonyms",
  Create_synonyms: "Create synonyms",
  List_synonyms: "List synonyms",
  Update_Mapping_API_key: "Update Mapping API key",
  Get_Mapping_API_key: "Get Mapping API key",
  Remove_elevate: "Remove elevate",
  List_elevate: "List elevate",
  Update_elevate: "Update elevate",
  Create_elevate: "Create elevate",
  Update_schedule: "Update schedule",
  Add_web_datasource: "Add web datasource",
  Update_Account: "Update Account",
};
