import { storeCreator } from "./util";

import { usageStoreApi } from './usage.store';

const store = {
  engines: [],
  currentEngine: null,
  currentEngineId: null,
  tabChanged:0,
  details: [],
  isLoading: false
};

const [useEngineStore, engineStoreApi] = storeCreator((setState, getState) => ({
  ...store,
  setEngines: (engines) => {
    engines && usageStoreApi.getState().setEngines(engines);
    setState(() => {
      return { engines: engines };
    });
  },
  getEngines: () => {
    return { engines: getState().engines };
  },
  getCurrentEngine: () => {
    return { currentEngine: getState().currentEngine };
  },
  setCurrentEngine: (engine, id) => {
    engine && usageStoreApi.getState().setEngineData(engine);
    setState(() => {
        return { currentEngine: engine, currentEngineId: id };
      });
  },
  setDetails: (details) => {
    setState(() => {
      return { details: details };
    });
  },
  getDetails: () => {
    return { details: getState().details };
  },
  setOnTabChange: () => {
    setState(() => {
      let num = Math.random();
      console.log("num", num);
        return { tabChanged: num };
      });
  },
  setLoading: (isLoading) => {
    setState(() => {
      return { isLoading: isLoading };
    });
  },
  reset: () => {
    setState(() => ({
      ...store,
    }));
  },
}));

export { useEngineStore, engineStoreApi };
