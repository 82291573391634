import React from "react";
import { Input, Form, Field, Select, SelectItem } from "../widgets";
import { Icon, Icon_Size, DropdownIcon } from "../widgets";
import { debounce } from "lodash";
import styles from "./styles.module.css";

export function UpdateProfileForm({
  form,
  onFinish,
  data = {},
  onFieldsChange,
  allroles = [],
}) {
  const onDebounceFieldChange = debounce(() => onFieldsChange(), 300);

  return (
    <Form
      name="update_profile_form"
      form={form}
      onFinish={onFinish}
      initialValues={{
        user_email: data.user_email,
        first_last_name: data.account_first_last_name,
        roles: data.roles && data.roles[0],
      }}
      onFieldsChange={(_, allFields) => {
        onDebounceFieldChange();
      }}
      className={styles.updateProfileForm}
    >
      <div className={`paragraph300 ${styles.fieldHeader}`}>Email </div>
      <Field
        name="user_email"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your email!",
          },
        ]}
      >
        <Input readOnly={true} />
      </Field>
      <div className={`paragraph300 ${styles.fieldHeader}`}>Roles</div>
      <Field
        name="roles"
        // rules={[
        //   {
        //     required: true,
        //     message: "Please select your favourite colors!",
        //   },
        // ]}
      >
        <Select
          placeholder="Please select roles"
          disabled={true}
          suffixIcon={<Icon src={DropdownIcon} size={Icon_Size.Icon12} />}
        >
          {allroles.map((item) => {
            return <SelectItem value={item.role}>{item.role}</SelectItem>;
          })}
        </Select>
      </Field>
      <div className={`paragraph300 ${styles.fieldHeader}`}>Name </div>
      <Field name="first_last_name" rules={[]}>
        <Input />
      </Field>
    </Form>
  );
}
