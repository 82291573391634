import React, { useEffect } from "react";
import { Button, BUTTON_SIZE, BUTTON_TYPE, Modal } from "../../widgets";
import {
  Icon,
  Icon_Size,
  CircleTickIcon,
  CloseSmallIcon,
  CloseIcon,
} from "../../widgets";
import { useUserStore } from "../../store";
import { getChargebeePlan } from "../../utils";
import { URLS } from "../../constants";

import styles from "./upgrademodal.module.css";

var chargeBeeInstance;

export function UpgradeModal({
  isOpen,
  onClose,
  onSave,
  allroles,
  data,
  editMode,
}) {
  let { currentUser } = useUserStore((state) => ({
    currentUser: state.currentUser,
  }));

  useEffect(() => {
    if (!chargeBeeInstance) {
      initChargeBee();
    } else {
      chargeBeeInstance.registerAgain && chargeBeeInstance.registerAgain();
    }
    chargeBeeInstance.setCheckoutCallbacks((cart) => {
      return {
        loaded: function () {
          console.log("checkout opened");
        },
        close: function () {
          console.log("checkout closed");
          setTimeout(() => {
            window.location.replace(
              window.location.origin + window.location.pathname
            );
          }, 1000);
        },
        success: function (hpid) {
          console.log("success", hpid, cart);
        },
        step: function (value) {
          // value -> which step in checkout
          console.log(value);
        },
      };
    });
  }, []);

  const initChargeBee = () => {
    let _plan = getChargebeePlan();
    chargeBeeInstance = window.Chargebee.init(_plan);
  };

  const onUpgrade = (planPriceId) => {
    let cart = chargeBeeInstance.getCart();
    //planPriceId - Plan price point ID is used to identify the product
    const planPriceQuantity = 1;
    const product = chargeBeeInstance.initializeProduct(
      planPriceId,
      planPriceQuantity
    );
    cart.replaceProduct(product);
    product.setCustomData({ referral: "yes", corporate_agent: "no" });
    let customer = {
      first_name: currentUser.account_first_last_name,
      // last_name: "M",
      email: currentUser.user_email,
    };
    cart.setCustomer(customer);
    cart.proceedToCheckout();
  };

  return (
    <Modal
      centered
      visible={isOpen}
      width={`90%`}
      footer={null}
      className="sourceFormModal"
      onCancel={onClose}
      maskClosable={false}
      closeIcon={<Icon src={CloseIcon} />}
    >
      <div className={styles.upgradePlansContnet}>
        <div className={`${styles.upgradeHeader} title900`}>
          A powerful
          <span className={styles.upgradeHeaderHighlight}>
            &nbsp;search experience&nbsp;
          </span>
          for your website without the learning curve.
        </div>
        <div className={styles.plans}>
          <div className={`${styles.plan} ${styles.plan1}`}>
            <div className={styles.planHeader}>
              <div className={`header700 weight600`}>Starter</div>
              <div className={`paragraph300 text400 ${styles.planSubText}`}>
              A search engine for a small business.
              </div>
              <div className={`title900 ${styles.price}`}>$19/mo</div>
              <Button
                className={`header500`}
                size={BUTTON_SIZE.LARGE}
                onClick={() => onUpgrade("starter")}
              >
                Upgade to Starter
              </Button>
              {/* <a href="javascript:void(0)" data-cb-type="checkout" data-cb-plan-id="cbdemo_free" >subscribe</a> */}
            </div>
            <div className={`${styles.planContent} header500 weight500`}>
              <div className={`body400 weight600`}>
                <b>Starter plan includes</b>
              </div>
              <div className={styles.planFeature}>
                <b>1</b> engine provided, view, collection
              </div>
              <div className={styles.planFeature}>
                <b>10k</b> search queries/month
              </div>
              <div className={styles.planFeature}>
                <b>5k</b> document
              </div>
              <div className={styles.planFeature}>Weekly, Monthly crawling</div>
              <div className={styles.planFeature}>Core Search features</div>
              <div className={styles.planFeature}>Unlimited users</div>
              <div className={styles.planFeature}>
                Advanced crawler settings
              </div>
            </div>
          </div>
          <div className={`${styles.plan} ${styles.plan2}`}>
            <div className={`${styles.mostPopular} header500`}>
              Most popular
            </div>
            <div className={styles.planHeader}>
              <div className={`header700 weight600`}>Standard</div>
              <div className={`paragraph300 text400 ${styles.planSubText}`}>
                A search engine for a single data source
              </div>
              <div className={`title900 ${styles.price}`}>$79/mo</div>
              <Button
                className={`header500`}
                size={BUTTON_SIZE.LARGE}
                type={BUTTON_TYPE.PRIMARY}
                onClick={() => onUpgrade("standard")}
              >
                Upgade to standard
              </Button>
              {/* <a href="javascript:void(0)" data-cb-type="checkout" data-cb-plan-id="cbdemo_free" >subscribe</a> */}
            </div>
            <div className={`${styles.planContent} header500 weight500`}>
              <div className={`body400 weight600`}>
                <b>Standard plan includes</b>
              </div>
              <div className={styles.planFeature}>
                <b>1</b> engine provided, view, collection
              </div>
              <div className={styles.planFeature}>
                <b>50k</b> search queries/month
              </div>
              <div className={styles.planFeature}>
                <b>25k</b> document
              </div>
              <div className={styles.planFeature}>Weekly, Monthly crawling</div>
              <div className={styles.planFeature}>Core Search features</div>
              <div className={styles.planFeature}>Unlimited users</div>
              <div className={styles.planFeature}>
                Advanced crawler settings
              </div>
            </div>
          </div>
          <div className={`${styles.plan} ${styles.plan3}`}>
            {/* <div className={`${styles.mostPopular} header500`}>
              Most popular
            </div> */}
            <div className={styles.planHeader}>
              <div className={`header700 weight600`}>Pro</div>
              <div className={`paragraph300 text400 ${styles.planSubText}`}>
                A search engine for a multiple data source
              </div>
              <div className={`title900 ${styles.price}`}>$159/mo</div>
              <Button
                className={`header500`}
                size={BUTTON_SIZE.LARGE}
                // type={BUTTON_TYPE.PRIMARY}
                onClick={() => onUpgrade("pro")}
              >
                Upgade to pro
              </Button>
            </div>
            <div className={`${styles.planContent} header500 weight500`}>
              <div className={`body400 weight600`}>Includes standard plus</div>
              <div className={styles.planFeature}>
                <b>3</b> engine provided, view, collection
              </div>
              <div className={styles.planFeature}>
                <b>150k</b> search queries/month
              </div>
              <div className={styles.planFeature}>
                <b>100k</b> document support
              </div>
              <div className={styles.planFeature}>
                Daily, Weekly, Monthly crawling
              </div>
              <div className={styles.planFeature}>Core Search features</div>
              <div className={styles.planFeature}>Personalized onboarding</div>
            </div>
          </div>
          {/* <div className={`${styles.plan} ${styles.plan3}`}>
            <div className={styles.planHeader}>
              <div className={`header700 weight600`}>Enterprise</div>
              <div className={`paragraph300 text400 ${styles.planSubText}`}>
                A company owned search engine
              </div>
              <div className={`title900 ${styles.price}`}>&nbsp;</div>
              <Button
                className={`header500`}
                size={BUTTON_SIZE.LARGE}
                onClick={() => {
                  window.open(URLS.CONTACT_PAGE);
                }}
              >
                Contact us
              </Button>
            </div>
            <div className={`${styles.planContent} header500 weight500`}>
              <div className={`body400 weight600`}>
                All exclusive pro features
              </div>
              <div className={styles.planFeature}>Dedicated Hardware</div>
              <div className={styles.planFeature}>Enterprise SLA</div>
              <div className={styles.planFeature}>IP Whitelisting</div>
              <div className={styles.planFeature}>
                Test (staging) environment
              </div>
              <div className={styles.planFeature}>
                Expert Implementation Team
              </div>
            </div>
          </div> */}
        </div>
        <div className={`${styles.compareHeader} title900`}>
          Compare plans and features
        </div>
        <div className={styles.comparePlans}>
          <div className={`${styles.features} body400`}>
            <div className={`${styles.featureHeader} header600`}>
              Core search features
            </div>
            <div>Mapping</div>
            <div>End user search API</div>
            <div>Automatic/Manual crawler</div>
            <div>Result rankings and promotions</div>
            <div> Synonyms</div>
            <div> Field weight</div>
            <div> Faceting customization</div>
            <div> Typo tolerance</div>
            <div> Autocomplete </div>
            <div> Role-based access controls</div>
          </div>
          <div className={styles.plan1Feature}>
            <div className={`${styles.featureHeader} header500`}>Starter</div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
          </div>
          <div className={styles.plan1Feature}>
            <div className={`${styles.featureHeader} header500`}>Standard</div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
          </div>
          <div className={styles.plan2Feature}>
            <div className={`${styles.featureHeader} header500`}>Pro</div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
          </div>
          <div className={styles.plan3Feature}>
            <div className={`${styles.featureHeader} header500`}>
              Enterprise
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
          </div>
        </div>
        <div className={styles.comparePlans}>
          <div className={`${styles.features} body400`}>
            <div className={`${styles.featureHeader} header600`}>
              Crawling and indexing
            </div>
            <div>Cross-domain search</div>
            <div>PDF/Document indexing</div>
            <div>Advanced crawler settings</div>
            <div>Custom extraction</div>
          </div>
          <div className={styles.plan1Feature}>
            <div className={`${styles.featureHeader} header500`}> </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
          </div>
          <div className={styles.plan1Feature}>
            <div className={`${styles.featureHeader} header500`}> </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
          </div>
          <div className={styles.plan2Feature}>
            <div className={`${styles.featureHeader} header500`}> </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
          </div>
          <div className={styles.plan3Feature}>
            <div className={`${styles.featureHeader} header500`}> </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
          </div>
        </div>
        <div className={styles.comparePlans}>
          <div className={`${styles.features} body400`}>
            <div className={`${styles.featureHeader} header600`}>Support</div>
            <div>Email Support</div>
            <div>Community and documentation</div>
            <div>Dedicated point of contact</div>
            <div>Personalized onboarding</div>
            <div>Integration consultation</div>
            <div>Self-service extension</div>
            <div>Expert implementation team</div>
          </div>
          <div className={styles.plan1Feature}>
            <div className={`${styles.featureHeader} header500`}> </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
          </div>
          <div className={styles.plan1Feature}>
            <div className={`${styles.featureHeader} header500`}> </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
          </div>
          <div className={styles.plan2Feature}>
            <div className={`${styles.featureHeader} header500`}> </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
          </div>
          <div className={styles.plan3Feature}>
            <div className={`${styles.featureHeader} header500`}> </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
          </div>
        </div>
        <div className={styles.comparePlans}>
          <div className={`${styles.features} body400`}>
            <div className={`${styles.featureHeader} header600`}>
              Security and collaboration
            </div>
            <div>SSO</div>
            <div>IP whitelisting</div>
            <div>DevOps automation</div>
          </div>
          <div className={styles.plan1Feature}>
            <div className={`${styles.featureHeader} header500`}> </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
          </div>
          <div className={styles.plan1Feature}>
            <div className={`${styles.featureHeader} header500`}> </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
          </div>
          <div className={styles.plan2Feature}>
            <div className={`${styles.featureHeader} header500`}> </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
            <div className={styles.iconParent}>
              <NotIndcluded />
            </div>
          </div>
          <div className={styles.plan3Feature}>
            <div className={`${styles.featureHeader} header500`}> </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
            <div className={styles.iconParent}>
              <Indcluded />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

function Indcluded() {
  // return <span className={styles.includedIcon}>✓</span>;
  return <Icon src={CircleTickIcon} size={Icon_Size.Icon16} />;
}

function NotIndcluded() {
  // return <span className={styles.notIncludedIcon}>x</span>;
  return <Icon src={CloseSmallIcon} size={Icon_Size.Icon16} />;
}
