import { SynonymsService } from "../service";
import { synonymStoreApi } from "../store";

export function getSynonyms(currentEngineId, currentViewId, page, size) {
  SynonymsService.list(currentEngineId, currentViewId, page, size).then(
    (response) => {
      if (response.synonyms) {
        synonymStoreApi
          .getState()
          .setList(response.synonyms, response.synonyms.numFound);
      }
    }
  );
}
