import React, { useEffect } from "react";
import { PageContainer } from "../container";
import { Link, useHistory } from "react-router-dom";
import { UserService, EngineService } from "../../shared/service";
import {
  Button,
  BUTTON_TYPE,
  Input,
  Form,
  Field,
  showError,
  Password,
} from "../../shared/widgets";
import { engineStoreApi } from "../../shared/store";
import styles from "./login.module.css";

export function LoginPage() {
  let history = useHistory();

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      onLoginSucess();
    }
  }, []);

  const onFinish = (values) => {
    engineStoreApi.getState().setLoading(true);
    UserService.signIn(values)
      .then((response) => {
        console.log(response, "response from login");
        if (response.token) {
          localStorage.setItem("token", response.token);
          onLoginSucess();
        } else if(response.mini_key) {
          localStorage.setItem("mini_key", response.mini_key);
          history.push("/activate");
        }
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        engineStoreApi.getState().setLoading(false);
      });
  };

  const onLoginSucess = () => {
    EngineService.getEngines()
      .then((response) => {
        if (response.engines && response.engines.length > 0) {
          engineStoreApi.getState().setEngines(response.engines);
          history.push(`/engine/${response.engines[0]}`);
        } else {
          history.push("/setup");
        }
      })
      .catch((error) => {
        if (error.code === 404) {
          history.push("/setup");
        }
      })
      .finally(() => {
        engineStoreApi.getState().setLoading(false);
      });
  };

  return (
    <PageContainer>
      <Form
        name="normal_login"
        className={styles.loginForm}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Field>
          <div className={styles.header}>
            <div className="header600 center">Welcome Back</div>
            <div className="tiny100 center">
              Sign in to your Keyspider account
            </div>
          </div>
        </Field>
        <Field
          name="user_email"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
          ]}
          className={"flexField"}
        >
          <Input placeholder="Email" type={"email"} />
        </Field>
        <Field
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
          className={"flexField"}
        >
          <Password type="password" placeholder="Password" />
        </Field>
        <Field className={styles.forgotPasswordRow}>
          <Link className={styles.forgotPassword} to="/forgotpassword">
            Forgot password
          </Link>
        </Field>
        <Field>
          <Button type={BUTTON_TYPE.PRIMARY} htmlType="submit" block={true}>
            Sign in
          </Button>
        </Field>
        <Field>
          <div className={styles.singUp}>
            <span className="text400">Don't have an account?</span>
            <Link className={styles.signUpLink} to="/signup">
              Sign Up
            </Link>
          </div>
        </Field>
      </Form>
    </PageContainer>
  );
}
