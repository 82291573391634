import { get, post, put } from "./service";

export const ElevateService = {
  list(engineName, viewName, start, rows) {
    return get(
      `/elevate/List?engine=${engineName}&view=${viewName}&start=${start}&rows=${rows}`
    );
  },
  create(data) {
    return post(`/elevate/Create`, { body: data }, true);
  },
  update(data) {
    return put(`/elevate/Update`, { body: data }, true);
  },
  remove(data) {
    return put(`/elevate/Remove`, { body: data }, true);
  },
};
