import { CollectionService } from "../service";
import { collectionStoreApi } from "../store";

export function getCollections(currentEngineId) {
  CollectionService.getCollections(currentEngineId)
    .then((response) => {
      collectionStoreApi.getState().setCollections(response.collections);
    })
    .catch((error) => {
      if (error.code === 404) {
        let url = `/setup/${currentEngineId}`;
        window.location.replace(url);
      }
    });
}

export function getCollection(currentEngineId, collId) {
  CollectionService.getCollection(currentEngineId, collId).then((response) => {
    collectionStoreApi
      .getState()
      .setCurrentCollection(response.collection_config, collId);
  });
}
