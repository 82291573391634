import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Steps, Step, showError } from "../../widgets";

import { EngineService, CollectionService, ViewService } from "../../service";
import { engineStoreApi } from "../../store";
import { CollectionForm, EngineForm } from "../../forms";

import { getEngines, getCollections, getViews } from "../../actions";

import styles from "./styles.module.css";

export function CreateEngine({
  onFinish,
  engineForm,
  collectionForm,
  onStepChange = () => {},
  hideContinue = false,
  hideSteps = false,
}) {
  const params = useParams();
  const [current, setCurrent] = useState(0);
  const [engineName, setEngineName] = useState("");
  const [createData, setCreateData] = useState({
    isEnabled: false,
    link_follow: "sitemap only",
    sitemap_locations: [
      {
        sitemap_url: "sitemap.xml",
      },
    ],
    start_indexing: true,
  });

  useEffect(() => {
    if(params.engineId && !hideSteps) {
      setEngineName(params.engineId);
      setCurrent(1);
    }
  },[]);

  const onChange = (index) => {
    onStepChange(index);
    setCurrent(index);
  };

  const onStepOneFinish = (values) => {
    setEngineName(values.engine);
    engineStoreApi.getState().setLoading(true);
    EngineService.createEngine(values)
      .then((response) => {
        if (response) {
          getEngines();
          setCurrent(1);
          onStepChange(1);
        }
      })
      .catch((error) => {
        console.log(error);
        showError(error.message);
      })
      .finally(() => {
        engineStoreApi.getState().setLoading(false);
      });
  };

  const onStepTwoFinish = (values) => {
    setCreateData(values);
    let data = {};
    data.engine = engineName;
    data.collection = values.collection;
    data.start_indexing = false;
    if(values.start_indexing) {
      data.start_indexing = true;
    }
    data.data_source_type = values.data_source_type;
    data.data_source = {};
    data.data_source.datasource_name= values.datasource_name;
    data.data_source.sources = [
      {
        path: values.data_source,
        path_type: "domain",
      },
    ];
    data.data_source.allowed_paths = [".*"];
    data.data_source.denied_paths = [];
    if (values.link_follow && values.isEnabled) {
      data.data_source.link_follow = values.link_follow;
      data.data_source.sitemap_locations = values.sitemap_locations.map(
        (sitemap) => {
          return sitemap.sitemap_url;
        }
      );
    } else {
      data.data_source.link_follow = "no sitemap";
      data.data_source.sitemap_locations = [];
    }
    engineStoreApi.getState().setLoading(true);
    CollectionService.createCollection(data)
      .then((response) => {
        if (response) {
          createView(values.collection);
        }
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        getCollections(engineName);
      });
    console.log(data);
  };

  const createView = (collection) => {
    ViewService.getViews(engineName)
      .then((response) => {
        if (response) {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
        let data = {};
        data.engine = engineName;
        data.view = "default_view";
        data.view_description = "deafult view description";
        data.collection_list = [collection];
        ViewService.create(data)
          .then((response) => {
            if (response.engine) {
              onFinish(engineName);
            }
          })
          .catch((error) => {
            showError(error.message);
          })
          .finally(() => {
            getViews(engineName);
            engineStoreApi.getState().setLoading(false);
          });
      });
  };
  return (
    <div>
      {!hideSteps && (
        <div className={styles.steps}>
          <Steps
            current={current}
            // onChange={onChange}  commented to disable step header 
          >
            <Step title="Build search engine" />
            <Step title="Create Collection" />
          </Steps>
        </div>
      )}
      {current === 0 && (
        <div>
          <EngineForm
            onFinish={onStepOneFinish}
            engineName={engineName}
            form={engineForm}
            hideContinue={hideContinue}
          />
        </div>
      )}
      {current === 1 && (
        <div>
          <CollectionForm
            form={collectionForm}
            onFinish={onStepTwoFinish}
            data={createData}
            showDesc={true}
            hideContinue={hideContinue}
          />
        </div>
      )}
    </div>
  );
}
