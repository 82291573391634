import { post, get, put, deleteReq } from "./service";

export const UserService = {
  signUp(data) {
    return post("/user/SignUp", { body: data }, true);
  },
  createUser(data) {
    return post("/user/CreateUser", { body: data }, true);
  },
  getUsers() {
    return get("/user/GetUsers");
  },
  updateRoles(data) {
    return post("/user/UpdateRoles", { body: data }, true);
  },
  availabelRoles() {
    return get("/user/AvailableRoles");
  },
  userDetails() {
    return get("/user/UserDetails");
  },
  deleteUser(user_id) {
    return deleteReq(`/user/DeleteUser?user_id=${user_id}`, {}, true);
  },
  signIn(data) {
    return post("/user/SignIn", { body: data });
  },
  forgotPassword(data) {
    return post("/user/ForgotPassword", { body: data }, true);
  },
  updateAccount(data) {
    return put("/user/UpdateAccount", { body: data }, true);
  },
  updatePassword(data) {
    return post("/user/UpdatePassword", { body: data }, true);
  },
  activate(data) {
    return post("/user/activate", { body: data }, true);
  },
  resendEmail(data) {
    return post("/user/Resend_activate_email", { body: data }, true);
  },
};
