import React, { useState, useEffect } from "react";
import {
  Button,
  BUTTON_TYPE,
  Input,
  Form,
  Field,
  FieldList,
  Radio,
  RadioGroup,
  Checkbox,
} from "../widgets";
import { checkName } from "./util";

import { MinusCircleOutlined } from "@ant-design/icons";
import { Icon, Icon_Size, BluePlusIcon } from "../widgets";

import styles from "./styles.module.css";

export function CollectionForm({
  onFinish,
  data = {},
  showDesc = false,
  form,
  hideContinue = false,
}) {
  const [dataSourceType, setDataSourceType] = useState(data.data_source_type || "website");

  // useEffect(() => {
  //   form.validateFields(["data_source_type"]);
  // }, [form, dataSourceType]);

  const onRadioChange = (e) => {
    setDataSourceType(e.target.value);
  };

  const [sitemapEnabled, setSitemapEnabled] = useState(false);
  const [startIndexing, setStartIndexing] = useState(false);
  const [sitemapType, setSitemapType] = useState("");

  useEffect(() => {
    if (data.isEnabled) {
      setSitemapEnabled(data.isEnabled);
    }
    if (data.link_follow) {
      setSitemapType(data.link_follow);
    }
    if (data.start_indexing) {
      setStartIndexing(data.start_indexing);
      form.setFieldsValue({
        start_indexing: data.start_indexing,
      });
    }
  }, [data]);

  return (
    <Form
      name="create_Collection_form"
      form={form}
      onFinish={onFinish}
      className={styles.createCollectionForm}
      initialValues={{
        datasource_name: data.datasource_name,
        collection: data.collection,
        data_source_type: data.data_source_type || "website" ,
        data_source:
          (data.data_source && data.data_source.source) || data.data_source,
        sitemap_locations: data.sitemap_locations,
        // start_indexing: data.start_indexing || false,
        link_follow: data.link_follow,
      }}
    >
      {showDesc && (
        <Field>
          <div>
            <div className="header600">Set up Collection</div>
            <div className="tiny100">
              Create Collection based on the data source types and configure
              them based on preferences.
            </div>
          </div>
        </Field>
      )}
      <div className={`paragraph300 ${styles.fieldHeader}`}>
        Collection name
      </div>
      <Field
        name="collection"
        rules={[
          {
            required: true,
            message: "Please input your collection name!",
          },
          {
            validator: checkName,
            message: "Only numbers, alphabets, - and _ are allowed",
          },
        ]}
      >
        <Input type="text" />
      </Field>
      <div className={`paragraph300 ${styles.fieldHeader}`}>
        Data source type
      </div>
      <Field
        name="data_source_type"
        rules={[
          {
            required: true,
            message: "Please input your datasource type!",
          },
        ]}
      >
        <RadioGroup
          value={dataSourceType}
          onChange={onRadioChange}
          className="body400"
        >
          <Radio value="website">Website</Radio>
          {/* <Radio value="xml">XML</Radio>
          <Radio value="api">API Push</Radio> */}
        </RadioGroup>
      </Field>
      {dataSourceType === "website" && (
        <>
          <div className={`paragraph300 ${styles.fieldHeader}`}>
            Datasource name
          </div>
          <Field
            name="datasource_name"
            rules={[
              {
                required: true,
                message: "Please input your datasource name!",
              },
              {
                validator: checkName,
                message: "Only numbers, alphabets, - and _ are allowed",
              },
            ]}
          >
            <Input type="text" />
          </Field>
          <div className="paragraph300">Website URL </div>
          <Field
            name="data_source"
            rules={[
              {
                required: true,
                message: "Please input your collection url!",
              },
              {
                type: "url",
                message: "Please enter a valid collection url!",
              },
            ]}
          >
            <Input type="text" />
          </Field>
        </>
      )}
      {/* Sitemap Start */}
      <Field name="isEnabled" className={styles.incluseSiteMap}>
        <Checkbox
          checked={sitemapEnabled}
          onChange={(e) => {
            form.setFieldsValue({
              isEnabled: e?.target?.checked,
            });
            setSitemapEnabled(e?.target?.checked);
          }}
        >
          Include sitemap
        </Checkbox>
      </Field>
      {sitemapEnabled && (
        <div className={styles.siteMapContent}>
          <div
            className={`paragraph300 ${styles.fieldHeader} ${styles.siteMapHeader}`}
          >
            Sitemap Preference
          </div>
          <Field name="link_follow">
            <RadioGroup
              className={`body400 ${styles.sitemapRadio}`}
              value={sitemapType}
              onChange={(e) => {
                form.setFieldsValue({
                  link_follow: e?.target?.value,
                });
                setSitemapType(e?.target?.value);
              }}
            >
              <div className={""}>
                <Radio value="sitemap only">Sitemap only</Radio>
                {sitemapType === "sitemap only" ? (
                  <div className={styles.siteMapUrls}>
                    <div className={`paragraph300 ${styles.fieldHeader}`}>
                      Sitemap url
                    </div>
                    <FieldList name="sitemap_locations">
                      {(sitemap_locations, { add, remove }) => (
                        <>
                          {sitemap_locations.map((field, index) => (
                            <div
                              key={field.key}
                              className={`${styles.sitemapSettingsForm} searchFieldList`}
                            >
                              <div className={styles.field}>
                                <Field
                                  {...field}
                                  name={[field.name, "sitemap_url"]}
                                  fieldKey={[field.fieldKey, "sitemap_url"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing field name",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Field Name" />
                                </Field>
                              </div>
                              {index !== sitemap_locations.length - 1 ? (
                                <MinusCircleOutlined
                                  onClick={() => remove(field.name)}
                                />
                              ) : (
                                <span
                                  onClick={() => add()}
                                  className={styles.plusicon}
                                >
                                  <Icon
                                    src={BluePlusIcon}
                                    size={Icon_Size.Icon16}
                                  />
                                </span>
                              )}
                            </div>
                          ))}
                        </>
                      )}
                    </FieldList>
                  </div>
                ) : (
                  <div className={styles.emptySiteMap} />
                )}
                <Radio value="sitemap and link follow">
                  Sitemap & Link follow
                </Radio>
                {sitemapType === "sitemap and link follow" && (
                  <div className={styles.siteMapUrls}>
                    <div className={`paragraph300 ${styles.fieldHeader}`}>
                      Sitemap url
                    </div>
                    <FieldList name="sitemap_locations">
                      {(sitemap_locations, { add, remove }) => (
                        <>
                          {sitemap_locations.map((field, index) => (
                            <div
                              key={field.key}
                              className={`${styles.sitemapSettingsForm} searchFieldList`}
                            >
                              <div className={styles.field}>
                                <Field
                                  {...field}
                                  name={[field.name, "sitemap_url"]}
                                  fieldKey={[field.fieldKey, "sitemap_url"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing field name",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Field Name" />
                                </Field>
                              </div>
                              {index !== sitemap_locations.length - 1 ? (
                                <MinusCircleOutlined
                                  onClick={() => remove(field.name)}
                                />
                              ) : (
                                <span
                                  onClick={() => add()}
                                  className={styles.plusicon}
                                >
                                  <Icon
                                    src={BluePlusIcon}
                                    size={Icon_Size.Icon16}
                                  />
                                </span>
                              )}
                            </div>
                          ))}
                        </>
                      )}
                    </FieldList>
                  </div>
                )}
              </div>
            </RadioGroup>
          </Field>
        </div>
      )}
      {/* Sitemap End */}
      <Field name="start_indexing">
        <Checkbox
          className="paragraph300"
          checked={startIndexing}
          onChange={(e) => {
            form.setFieldsValue({
              start_indexing: e?.target?.checked,
            });
            setStartIndexing(e?.target?.checked);
          }}
        >
          Start Indexing
        </Checkbox>
        <div className="tiny100">
          {/* By clicking the checkbox, the data sources will be indexed. You can
          also proceed with the configuration without initiating the indexing
          process. */}
          By checking the box, you allow the crawlers to index the collection.
          The setup can be carried out without initiating indexing.
        </div>
      </Field>
      {!hideContinue && (
        <Field>
          <Button type={BUTTON_TYPE.PRIMARY} htmlType="submit" block={true}>
            Continue
          </Button>
        </Field>
      )}
    </Form>
  );
}
