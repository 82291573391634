import { post, get, put } from "./service";

export const IndexingService = {
  addSource(data) {
    return post("/indexing/AddSources", { body: data }, true);
  },
  deleteSource(data) {
    return post("/indexing/DeleteSources", { body: data }, true);
  },
  recrawlSource(data) {
    return post("/indexing/RecrawlSource", { body: data }, true);
  },
  docInfo(engineName, collectionId, start, rows) {
    // &start=${start}&rows=${rows}
    return get(`/indexing/DocInfo?engine=${engineName}&collection=${collectionId}`);
  },
  recrawlAll(data) {
    return post("/indexing/Recrawl", { body: data }, true);
  },
  addDocument(data) {
    return post("/indexing/AddDocument", { body: data }, true);
  },
  getCrawling(engineName, start=0, rows=50) {
    return get(`/indexing/GetCrawling?engine=${engineName}&start=${start}&rows=${rows}`);
  },
  manageIndexing(data) {
    return put("/indexing/ManageIndexing", { body: data }, true);
  },
};
