import React, { useEffect, useState } from "react";
import {
  Button,
  BUTTON_TYPE,
  Input,
  Form,
  Field,
  FieldList,
  Select,
  SelectItem,
  Checkbox,
  RadioGroup,
  Radio,
} from "../widgets";
import { checkName } from "./util";
import { MinusCircleOutlined } from "@ant-design/icons";
import { Icon, Icon_Size, BluePlusIcon } from "../widgets";
import styles from "./styles.module.css";

export function EditDataSourceForm({
  onFinish,
  data = {},
  form,
  readOnly = false,
}) {
  const [sitemapEnabled, setSitemapEnabled] = useState(false);
  const [sitemapType, setSitemapType] = useState("");

  useEffect(() => {
    if (data.isEnabled) {
      setSitemapEnabled(data.isEnabled);
    }
    if (data.link_follow) {
      setSitemapType(data.link_follow);
    }
  }, [data]);

  return (
    <Form
      name="edit_datasource_form"
      form={form}
      onFinish={onFinish}
      initialValues={{
        datasource_name: data.datasource_name,
        source: data.source,
        schedule: data.schedule,
        // enable_sitemap: data.link_follow !== "no sitemap",
        sitemap_type: data.link_follow,
        sitemap_locations: data.sitemap_locations,
      }}
    >
      <div className={`paragraph300 ${styles.fieldHeader}`}>Datasource Name</div>
      <Field
        name="datasource_name"
        rules={[
          {
            required: true,
            message: "Please input your datasource name!",
          },
          {
            validator: checkName,
            message: "Only numbers, alphabets, - and _ are allowed",
          },
        ]}
      >
        <Input type="text" readOnly={readOnly} />
      </Field>
      <div className={`paragraph300 ${styles.fieldHeader}`}>Datasource URL</div>
      <Field
        name="source"
        rules={[
          {
            required: true,
            message: "Please input your view name!",
          },
        ]}
      >
        <Input type="text" readOnly={readOnly} />
      </Field>
      <div className={`paragraph300 ${styles.fieldHeader}`}>Schedule </div>
      <Field
        name="schedule"
        rules={[
          {
            required: true,
            message: "Please select schedule",
          },
        ]}
      >
        <Select placeholder="Schedule" className={styles.schedule}>
          {schedule.map((item) => {
            return (
              <SelectItem value={item} className={styles.schedule}>
                {item}
              </SelectItem>
            );
          })}
        </Select>
      </Field>
      <Field name="isEnabled" className={styles.incluseSiteMap}>
        <Checkbox
          checked={sitemapEnabled}
          onChange={(e) => {
            form.setFieldsValue({
              isEnabled: e?.target?.checked,
            });
            setSitemapEnabled(e?.target?.checked);
          }}
        >
          Include sitemap
        </Checkbox>
      </Field>
      {sitemapEnabled && (
        <div className={styles.siteMapContent}>
          <div
            className={`paragraph300 ${styles.fieldHeader} ${styles.siteMapHeader}`}
          >
            Sitemap Preference
          </div>
          <Field name="link_follow">
            <RadioGroup
              className={`body400 ${styles.sitemapRadio}`}
              value={sitemapType}
              onChange={(e) => {
                form.setFieldsValue({
                  link_follow: e?.target?.value,
                });
                setSitemapType(e?.target?.value);
              }}
            >
              <div className={""}>
                <Radio value="sitemap only">Sitemap only</Radio>
                {sitemapType === "sitemap only" ? (
                  <div className={styles.siteMapUrls}>
                    <div className={`paragraph300 ${styles.fieldHeader}`}>
                      Sitemap url
                    </div>
                    <FieldList
                      name="sitemap_locations"
                      rules={[
                        {
                          min: 1,
                          type: "array",
                          message: "Please select atleast one field",
                        },
                      ]}
                    >
                      {(sitemap_locations, { add, remove }) => (
                        <>
                          {sitemap_locations.map((field, index) => (
                            <div
                              key={field.key}
                              className={`${styles.sitemapSettingsForm} searchFieldList`}
                            >
                              <div className={styles.field}>
                                <Field
                                  {...field}
                                  name={[field.name, "sitemap_url"]}
                                  fieldKey={[field.fieldKey, "sitemap_url"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing field name",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Field Name" />
                                </Field>
                              </div>
                              {/* {index !== sitemap_locations.length - 1 ? (
                                <MinusCircleOutlined
                                  onClick={() => remove(field.name)}
                                />
                              ) : (
                                <span
                                  onClick={() => add()}
                                  className={styles.plusicon}
                                >
                                  <Icon
                                    src={BluePlusIcon}
                                    size={Icon_Size.Icon16}
                                  />
                                </span>
                              )} */}
                              <MinusCircleOutlined
                                onClick={() => remove(field.name)}
                              />
                            </div>
                          ))}
                          {sitemap_locations.length === 0 && (
                            <Field>
                              <span className="ant-form-item-explain ant-form-item-explain-error">
                                Kindly add atleast one sitemap
                              </span>
                            </Field>
                          )}
                          <Button
                            type={BUTTON_TYPE.LINK}
                            onClick={() => add()}
                            style={{
                              paddingLeft: "0",
                              top: "-.75rem",
                            }}
                          >
                            Add URL
                          </Button>
                        </>
                      )}
                    </FieldList>
                  </div>
                ) : (
                  <div className={styles.emptySiteMap} />
                )}
                <Radio value="sitemap and link follow">
                  Sitemap & Link follow
                </Radio>
                {sitemapType === "sitemap and link follow" && (
                  <div className={styles.siteMapUrls}>
                    <div className={`paragraph300 ${styles.fieldHeader}`}>
                      Sitemap url
                    </div>
                    <FieldList
                      name="sitemap_locations"
                      rules={[
                        {
                          min: 1,
                          type: "array",
                          message: "Please select atleast one field",
                        },
                      ]}
                    >
                      {(sitemap_locations, { add, remove }) => (
                        <>
                          {sitemap_locations.map((field, index) => (
                            <div
                              key={field.key}
                              className={`${styles.sitemapSettingsForm} searchFieldList`}
                            >
                              <div className={styles.field}>
                                <Field
                                  {...field}
                                  name={[field.name, "sitemap_url"]}
                                  fieldKey={[field.fieldKey, "sitemap_url"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing field name",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Field Name" />
                                </Field>
                              </div>
                              {/* {index !== sitemap_locations.length - 1 ? (
                                <MinusCircleOutlined
                                  onClick={() => remove(field.name)}
                                />
                              ) : (
                                <span
                                  onClick={() => add()}
                                  className={styles.plusicon}
                                >
                                  <Icon
                                    src={BluePlusIcon}
                                    size={Icon_Size.Icon16}
                                  />
                                </span>
                              )} */}
                              <MinusCircleOutlined
                                onClick={() => remove(field.name)}
                              />
                            </div>
                          ))}
                          {sitemap_locations.length === 0 && (
                            <Field>
                              <span className="ant-form-item-explain ant-form-item-explain-error">
                                Kindly add atleast one sitemap
                              </span>
                            </Field>
                          )}
                          <Button
                            type={BUTTON_TYPE.LINK}
                            onClick={() => add()}
                            style={{
                              paddingLeft: "0",
                              top: "-.75rem",
                            }}
                          >
                            Add URL
                          </Button>
                        </>
                      )}
                    </FieldList>
                  </div>
                )}
              </div>
            </RadioGroup>
          </Field>
        </div>
      )}
    </Form>
  );
}

const schedule = ["monthly", "weekly", "daily", "default"];
