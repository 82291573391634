import React from "react";

import { Empty as AntEmpty} from 'antd';

import "antd/es/empty/style/css";
// import styles from './button.module.css';

export function EmptyState({ children, ...props }) {
  return <AntEmpty {...props}>{children}</AntEmpty>;
}
