import { put, get } from "./service";

export const UserPlanService = {
  updateSubscription(data) {
    return put("/plan/UpdateSubscription", { body: data }, true);
  },
  getCardInfo(){
    return get("/plan/payment_info?get=card");
  },
  getInvoiceInfo(){
    return get("/plan/payment_info?get=invoice");
  }
};
