import { get, put } from "./service";

export const TuningService = {
  list(engineName, viewName, keyword) {
    return get(
      `/tuning/list?engine=${engineName}&view=${viewName}&keyword=${keyword}`
    );
  },
  add(data) {
    return put("/tuning/add", { body: data });
  },
  search(queryData, apiKey, rootId) {
    const qs = new URLSearchParams(queryData);
    return get(`/${rootId}/${apiKey}/search?${qs}`, {}, false, true);
  },
  searchWithPagination(queryData, apiKey, rootId, start, rows) {
    const qs = new URLSearchParams(queryData);
    return get(`/${rootId}/${apiKey}/search?${qs}&start=${start}&rows=${rows}`, {}, false, true);
  },
  suggest(queryData, apiKey, rootId) {
    const qs = new URLSearchParams(queryData);
    return get(`/${rootId}/${apiKey}/suggest?${qs}`, {}, false, true);
  },
  spell(queryData, apiKey, rootId) {
    const qs = new URLSearchParams(queryData);
    return get(`/${rootId}/${apiKey}/spell?${qs}`, {}, false, true);
  },

};
