import React from "react";
// import { Popover } from "../../widgets";
import styles from "./styles.module.css";

export function ArrayDataRenderer({ data = [] }) {
  // const content = (
  //   <div className={styles.popup}>
  //     {data.map((item, index) => {
  //       return (
  //         <span>
  //           {item}
  //           {index !== data.length - 1 && ", "}
  //         </span>
  //       );
  //     })}
  //   </div>
  // );

  return data.length <= 2 ? (
    <div className={``}>
      {data.map((item, index) => {
        return (
          <span>
            {item}
            {index !== data.length - 1 && ", "}
          </span>
        );
      })}
    </div>
  ) : (
    <div>
      <span>{data[0] + ", "}</span>
      <span>{data[1] + " "}</span>
      {/* <Popover content={content} title="Title" trigger="hover"> */}
      <span className={styles.popupTrigger}>& {data.length - 3} more</span>
      {/* </Popover> */}
    </div>
  );
}

export function CommaSeparatedData({ data = [] }) {
  return (
    <div>
      {data.map((item, index) => {
        return (
          <span>
            {item}
            {index !== data.length - 1 && ", "}
          </span>
        );
      })}
    </div>
  );
}
