import React from 'react';
import { Steps  as AntSteps } from 'antd';
import "antd/es/steps/style/css";

// import styles from './button.module.css';

export function Steps({
    ...props
}) {
  return (
    <AntSteps {...props} />
  );
}


export function Step({
    ...props
}) {
  return (
    <AntSteps.Step {...props} />
  );
}