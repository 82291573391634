export function onSearchData(search, data, columns) {
  let searchdata = [...data];
  if (search && search.length > 0) {
    let props = columns.map((item) => {
      return item.key;
    });
    searchdata = searchdata.filter((item) => {
      let result = false;
      props.forEach((key) => {
        if (isString(item[key])) {
          result = result || searchString(search.toLowerCase(), item[key]);
        } else if (isArray(item[key])) {
          //array
          result = result || searchArray(search.toLowerCase(), item[key]);
        } else if (isObject(item[key])) {
          //object
          result = result || searchObject(search.toLowerCase(), item[key]);
        } else if (typeof item[key] === "number") {
          result = result || searchNumber(search, item[key]);
        } else if (typeof item[key] === "boolean") {
          result = result || searchBoolean(search, item[key]);
        }
      });
      return result;
    });
  }
  console.log("On Saearch", search, searchdata);
  return searchdata;
}

function searchArray(search, data) {
  let result = false;
  for (let i = 0; i < data.length; i++) {
    if (data[i] && data[i].toLowerCase().indexOf(search) >= 0) {
      result = result || true;
      break;
    }
  }
  return result;
}

function searchObject(search, data) {
  let result = false;
  let keys = Object.keys(data);
  for (let i = 0; i < keys.length; i++) {
    if (data[keys[i]] && data[keys[i]].toLowerCase().indexOf(search) >= 0) {
      result = result || true;
      break;
    }
  }
  return result;
}

function searchString(search, data) {
  return data.toLowerCase().indexOf(search) >= 0;
}

function searchNumber(search, data) {
  console.log(data.toString().indexOf(search) >= 0, "checkkk here");
  return data.toString().indexOf(search) >= 0;
}

function searchBoolean(search, data) {
  return data.toString().indexOf(search) >= 0;
}

function getTypeOfObj(data) {
  return typeof data;
}

export function isString(data) {
  return getTypeOfObj(data) === "string";
}

export function isArray(data) {
  return getTypeOfObj(data) === "object" && data.length;
}

export function isObject(data) {
  return getTypeOfObj(data) === "object" && !data.length;
}
