import React from "react";
import { Button, BUTTON_TYPE, Input, Form, Field } from "../widgets";
import { checkName } from "./util";
import styles from "./styles.module.css";

export function EngineForm({
  onFinish,
  engineName,
  form,
  hideContinue = false,
}) {
  return (
    <Form
      form={form}
      name="create_engine_form"
      onFinish={onFinish}
      initialValues={{
        engine: engineName,
      }}
    >
      <div className={styles.engineHeader}>
        <div className="header600">Name your search engine</div>
        <div className="tiny100">
          Let's start by giving your search engine a name and configure it based
          on your unique requirements.
          {/* Let's begin by providing a suitable name for the search engine. */}
        </div>
      </div>
      <div className={`paragraph300 ${styles.fieldHeader}`}>Engine name</div>
      <Field
        name="engine"
        rules={[
          {
            required: true,
            message: "Please input your engine name!",
          },
          {
            validator: checkName,
            message: "Only numbers, alphabets, - and _ are allowed",
          },
        ]}
      >
        <Input type="text" />
      </Field>
      {!hideContinue && (
        <Field>
          <Button type={BUTTON_TYPE.PRIMARY} htmlType="submit" block={true}>
            Continue
          </Button>
        </Field>
      )}
    </Form>
  );
}
