import React, { useEffect, useState } from "react";
import {
  Input,
  TextArea,
  Form,
  Field,
  FieldList,
  RadioGroup,
  RadioButton,
  Select,
  SelectItem,
  Spin,
  useForm,
  Button,
} from "../widgets";
import { MinusCircleOutlined } from "@ant-design/icons";
import { PlainJsonEditor } from "react-plain-json-editor";
import { Icon, Icon_Size, DropdownIcon, BluePlusIcon } from "../widgets";

import styles from "./styles.module.css";

const isErrorCode = Math.random();

export function CreateIndexingForm({
  onFinish,
  data = {},
  selectData = [],
  onTypeChange = () => {},
  disableConf = false,
  onLoad = () => {},
  schedule = [],
}) {
  const [formData, setFormData] = useState(data);
  const [form] = useForm();

  useEffect(() => {
    setFormData(null);
    setTimeout(() => {
      setFormData(data);
    }, 400);
  }, [data]);

  useEffect(() => {
    onLoad(form);
  }, []);

  return (
    <>
      {formData !== null ? (
        <Form
          name="create_indexing_form"
          form={form}
          onFinish={onFinish}
          initialValues={{
            _id: formData._id,
            current_value: getCurrentValue(formData),
            description: formData.description,
            comments: formData.comments,
            default_value: formData.default_value,
          }}
          className={styles.createIndexingForm}
        >
          <div className={`paragraph300 ${styles.fieldHeader}`}>Parameter</div>
          <Field
            name="_id"
            rules={[
              {
                required: true,
                message: "Please select paramter",
              },
            ]}
          >
            {disableConf ? (
              <Input type="text" disabled />
            ) : (
              <Select
                placeholder="Select parameter"
                onChange={onTypeChange}
                allowClear
                disbaled={disableConf}
                suffixIcon={<Icon src={DropdownIcon} size={Icon_Size.Icon12} />}
              >
                {selectData.map((item) => {
                  return <SelectItem value={item._id}>{item.name}</SelectItem>;
                })}
              </Select>
            )}
          </Field>
          <div className={`paragraph300 ${styles.fieldHeader}`}>Value </div>
          {getCurrentValueRenderer(
            formData.data_type,
            formData.current_value,
            formData.setting_name,
            schedule
          )}
          <div className={`paragraph300 ${styles.fieldHeader}`}>
            Description
          </div>
          <Field>
            <div className="body400">
              {formData.description ? formData.description : "--"}
            </div>
          </Field>
          <div className={`paragraph300 ${styles.fieldHeader}`}>Comments</div>
          <Field name="comments">
            <TextArea rows={3} />
          </Field>
          {formData.default_value?.length !== 0 && (
            <>
              <div className={`paragraph300 ${styles.fieldHeader}`}>
                Default value
              </div>
              <>{getDefaultValueRenderer(formData.data_type)}</>
            </>
          )}
        </Form>
      ) : (
        <div className={styles.loading}>
          <span>
            <Spin />
          </span>
        </div>
      )}
    </>
  );
}

function getCurrentValueRenderer(dataType, value, setting_name, schedule) {
  if (setting_name === "field_selector" && dataType === "list") {
    return (
      <FieldList name="current_value">
        {(current_value, { add, remove }) => (
          <>
            {current_value.map((field) => (
              <div
                key={field.key}
                className={`${styles.fieldSettingsForm} searchFieldList`}
              >
                <div className={styles.field}>
                  <Field
                    {...field}
                    name={[field.name, "field_name"]}
                    fieldKey={[field.fieldKey, "field_name"]}
                    rules={[{ required: true, message: "Missing field name" }]}
                  >
                    <Input placeholder="Field Name" />
                  </Field>
                </div>
                <div className={styles.field}>
                  <Field
                    {...field}
                    name={[field.name, "selector_string"]}
                    fieldKey={[field.fieldKey, "selector_string"]}
                    rules={[{ required: true, message: "Missing query value" }]}
                  >
                    <Input placeholder="Selector string" />
                  </Field>
                </div>
                <div className={styles.field}>
                  <Field
                    {...field}
                    name={[field.name, "selector_type"]}
                    fieldKey={[field.fieldKey, "selector_type"]}
                    rules={[{ required: true, message: "Missing query value" }]}
                  >
                    <Select placeholder="Selector type" defaultValue="html">
                      {selectortype.map((item) => {
                        return <SelectItem value={item}>{item}</SelectItem>;
                      })}
                    </Select>
                  </Field>
                </div>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </div>
            ))}
            <Field>
              <Button
                type="link"
                onClick={() => add()}
                className={styles.addFieldButton}
                icon={<Icon src={BluePlusIcon} size={Icon_Size.Icon16} />}
              >
                Add filter
              </Button>
            </Field>
          </>
        )}
      </FieldList>
    );
  }

  if (setting_name === "schedule" && dataType === "string") {
    return (
      <Field
        name="current_value"
        rules={[{ required: true, message: "Please pick an item!" }]}
      >
        <Select placeholder="Please select schedule">
          {schedule.map((item) => {
            return <SelectItem value={item}>{item}</SelectItem>;
          })}
        </Select>
      </Field>
    );
  }
  switch (dataType) {
    case "boolean":
      return (
        <Field
          name="current_value"
          rules={[{ required: true, message: "Please pick an item!" }]}
        >
          <RadioGroup defaultValue="true" buttonStyle="solid">
            <RadioButton value="true">True</RadioButton>
            <RadioButton value="false">False</RadioButton>
          </RadioGroup>
        </Field>
      );
    case "integer":
      return (
        <Field
          name="current_value"
          rules={[{ required: true, message: "Please enter a value" }]}
        >
          <Input type="number" value={parseInt(value)} />
        </Field>
      );
    case "float":
      return (
        <Field
          name="current_value"
          rules={[{ required: true, message: "Please enter a value" }]}
        >
          <Input type="number" value={parseFloat(value)} />
        </Field>
      );
    case "json":
      return (
        <Field name="current_value" rules={[{ validator: validateJSON }]}>
          <JsonEditor />
        </Field>
      );
    case "list":
      return (
        <Field
          name="current_value"
          rules={[{ required: true, message: "Please enter a value" }]}
        >
          <Input type="text" value={value && value.join(",")} />
        </Field>
      );
    default:
      return (
        <Field
          name="current_value"
          rules={[{ required: true, message: "Please enter a value" }]}
        >
          <Input type="text" />
        </Field>
      );
  }
}

function getDefaultValueRenderer(dataType) {
  switch (dataType) {
    case "json":
      return (
        <Field name="default_value">
          <PlainJsonEditor
            readOnly={true}
            disabled={true}
            styles={{
              root: {
                height: "unset",
                width: "unset",
              },
              textarea: {
                backgroundColor: "rgba(0,0,0,0.8)",
                color: "#CFF",
                padding: 12,
                fontSize: "1.2rem",
                lineHeight: "1.5rem",
                fontFamily: "monospace",
              },
            }}
          />
        </Field>
      );
    default:
      return (
        <Field name="default_value">
          <Input type="text" disabled />
        </Field>
      );
  }
}

function getCurrentValue(data) {
  if (data.data_type === "boolean") {
    return data.current_value ? "true" : "false";
  } else if (
    data.data_type === "list" &&
    data.setting_name !== "field_selector"
  ) {
    return data.current_value?.join();
  } else {
    return data.current_value;
  }
}

function validateJSON(_, value) {
  if (
    value === undefined ||
    value === null ||
    (value && value.__isError && value.__isError === isErrorCode) ||
    (Object.keys(value) && Object.keys(value).length === 0)
  ) {
    return Promise.reject(new Error(value.message || "Should not be empty"));
  }
  return Promise.resolve();
}

function JsonEditor({ value, onChange }) {
  const [data, setData] = useState(value);

  useEffect(() => {
    setData(value);
  }, [value]);

  const handleChange = (e) => {
    onChange(e);
  };

  const onSerializeError = (e) => {
    onChange({ __isError: isErrorCode, message: e.message });
  };
  return (
    <PlainJsonEditor
      styles={{
        root: {
          height: "unset",
          width: "unset",
        },
        textarea: {
          backgroundColor: "rgba(0,0,0,0.8)",
          color: "#CFF",
          padding: 12,
          fontSize: "1.2rem",
          lineHeight: "1.5rem",
          fontFamily: "monospace",
        },
      }}
      showInnerError={false}
      value={data}
      onChange={handleChange}
      onSerializeError={onSerializeError}
    />
  );
}

// const schedule = ["monthly", "weekly", "daily", "default"];
const selectortype = ["css", "html"];
